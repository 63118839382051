const itens = [
  {
    destino: '/dashboard',
    titulo: 'Dashboard',
    icone: 'fa fa-fw fa-home',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/mensuracao',
    titulo: 'Mensuração',
    icone: 'fa fa-fw fa-chart-line',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/historico',
    titulo: 'Histórico',
    icone: 'fa fa-fw fa-chart-area',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/relatorio',
    titulo: 'Relatório',
    icone: 'fa fa-fw fa-paste',
    permissao: [1, 2],
  },
  {
    destino: '/taxa-sucesso',
    titulo: 'Taxa de Sucesso',
    icone: 'fa fa-fw fa-check',
    permissao: [1, 2],
  },
  {
    destino: '/produtos',
    titulo: 'Produtos',
    icone: 'fa fa-fw fa-box',
    permissao: [1, 2],
  },
  {
    destino: '/premios',
    titulo: 'Prêmios',
    icone: 'fa fa-fw fa-gifts',
    permissao: [2],
  },
  {
    destino: '/pontuacoes',
    titulo: 'Pontuações',
    icone: 'fa fa-fw fa-user-plus',
    permissao: [0, 1, 2, 3],
    subItens: [
      {
        destino: '/pontuado',
        titulo: 'Pontuado',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/encerrado',
        titulo: 'Encerrado',
        permissao: [0, 1, 2, 3],
      },
    ],
  },
  {
    destino: '/antifraude',
    titulo: 'Antifraude',
    icone: 'fa fa-fw fa-file-contract',
    permissao: [1, 2],
  },
  {
    destino: '/compras-pontuacao',
    titulo: 'Compras x Pontuação',
    icone: 'fa fa-fw fa-shopping-bag',
    permissao: [1, 2],
  },
  {
    destino: '/produtos-vendidos',
    titulo: 'Produtos Vendidos',
    icone: 'fa fa-fw fa-truck-loading',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/pesquisa-de-mercado',
    titulo: 'Pesquisa de Mercado',
    icone: 'fa fa-fw fa-file-signature',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/resgates',
    titulo: 'Resgates',
    icone: 'fa fa-fw fa-gift',
    permissao: [0, 1, 2],
  },
  {
    destino: '/fotos-videos',
    titulo: 'Fotos e Vídeos',
    icone: 'fa fa-fw fa-camera',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/cartoes',
    titulo: 'Cartões',
    icone: 'fa fa-fw fa-credit-card',
    permissao: [1, 2],
  },
  {
    destino: '/vendedores',
    titulo: 'Vendedores',
    icone: 'fa fa-fw fa-users',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/dentais',
    titulo: 'Dentais',
    icone: 'fa fa-fw fa-store',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/redes',
    titulo: 'Redes',
    icone: 'fa fa-fw fa-industry',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/configuracao-taxa-sucesso',
    titulo: 'Conf. Taxa de Sucesso',
    icone: 'fa fas fa-cog',
    permissao: [0, 1, 2, 3],
  },
  {
    destino: '/logs',
    titulo: 'Logs',
    icone: 'fa fa-fw fa-file-alt',
    permissao: [2],
  },
  {
    destino: '/cadastrar',
    titulo: 'Cadastrar',
    icone: 'fa fa-fw fa-edit',
    permissao: [1, 2],
    subItens: [
      {
        destino: '/administrador',
        titulo: 'Administrador',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/rede',
        titulo: 'Rede',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/revenda',
        titulo: 'Dental',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/cartao',
        titulo: 'Cartão',
        permissao: [2],
      },
      {
        destino: '/premio',
        titulo: 'Prêmio',
        permissao: [2],
      },
      {
        destino: '/produto',
        titulo: 'Produto',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/parceiro',
        titulo: 'Vendedor Dental',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/pesquisa-mercado',
        titulo: 'Pesquisa de Mercado',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/configuracao-taxa-sucesso',
        titulo: 'Configuração Taxa de Sucesso',
        permissao: [0, 1, 2, 3],
      },
    ],
  },
  {
    destino: '/processar',
    titulo: 'Processar',
    icone: 'fa fa-fw fa-upload',
    permissao: [1, 2],
    subItens: [
      {
        destino: '/produtos',
        titulo: 'Produtos',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/vendas',
        titulo: 'Vendas',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/historico',
        titulo: 'Histórico',
        permissao: [0, 1, 2, 3],
      },
      // {
      //   destino: '/nova-venda',
      //   titulo: 'Nova Venda',
      //   permissao: 0,
      // },
      {
        destino: '/pontuacoes',
        titulo: 'Pontuações Pendentes',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/saldo-cartao',
        titulo: 'Saldo Cartão',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/relatorios',
        titulo: 'Relatórios',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/correcao-pontuacao',
        titulo: 'Correção de Pontuação',
        permissao: [0, 1, 2, 3],
      },
      {
        destino: '/foto-video',
        titulo: 'Foto/Vídeo',
        permissao: [0, 1, 2, 3],
      },
    ],
  },
];

export default itens;
